<template>
  <div>
    <CreateToDo
      v-if="showCreateToDo"
      :showModal="showCreateToDo"
      :onClickCancel="hideModal"
      :locationId="locationId"
      :typeId="typeId"
      :itemId="itemId"
      @todoCreated="todoCreated"
      :workEnvironment="workEnvironment"
      :environmentId="environmentId"
    />

    <DeleteToDo
      v-if="showDeleteToDo"
      :showModal="showDeleteToDo"
      :todo="selectedToDo"
      :onClickCancel="hideModal"
      @todoDeleted="todoDeleted"
    />

    <EditToDo
      v-if="showEditToDo"
      :showModal="showEditToDo"
      :todo="selectedToDo"
      :onClickCancel="hideModal"
      @todoEdited="todoUpdated"
    />

    <ShareToDo
      v-if="showShareToDo"
      :showModal="showShareToDo"
      :todo="selectedToDo"
      :onClickCancel="hideModal"
      @todoShared="todoUpdated"
      :workEnvironment="workEnvironment"
      :environmentId="environmentId"
    />

    <nav>
      <div class="level">
        <div class="level-left"></div>
        <div v-if="viewType === 'small'" class="is-size-7 level-right">
          <a @click="setShowCreateToDo">
            <span class="icon is-small">
              <font-awesome-icon :icon="['fas', 'plus']" />
            </span>
            <span>Add to-do</span>
          </a>
        </div>
      </div>

      <div
        v-if="viewType === 'normal'"
        class="actionBar has-background-white-ter has-padding has-margin-bottom"
      >
        <div class="level is-small">
          <div class="level-left"></div>
          <div class="level-right">
            <a @click="setShowCreateToDo">
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'plus']" />
              </span>
              <span>Add to-do</span>
            </a>
          </div>
        </div>
      </div>
    </nav>

    <div
      class="tabs"
      v-if="showStatusMenu"
      :class="{ 'is-small': viewType === 'small' }"
    >
      <ul>
        <li :class="{ 'is-active': toDoView === 'allToDos' }">
          <a @click="toDoView = 'allToDos'">All</a>
        </li>
        <li :class="{ 'is-active': toDoView === 'openToDos' }">
          <a @click="toDoView = 'openToDos'">Open</a>
        </li>
        <li :class="{ 'is-active': toDoView === 'expiredToDos' }">
          <a @click="toDoView = 'expiredToDos'">Expired</a>
        </li>
        <li :class="{ 'is-active': toDoView === 'completedToDos' }">
          <a @click="toDoView = 'completedToDos'">Completed</a>
        </li>
      </ul>
    </div>
    <template>
      <ui-loader v-if="isLoading" />
    </template>
    <template v-if="!isLoading">
      <transition-group name="fade" mode="out-in">
        <Message
          key="nothingToShow"
          v-if="filteredToDos.length === 0 && !isLoading"
          :message="'No to-dos found'"
          :size="viewType === 'small' ? 'is-small' : ''"
        />

        <table
          v-else
          key="table"
          class="table is-fullwidth is-striped is-hoverable"
        >
          <thead v-if="viewType !== 'small'">
            <tr>
              <th>Subject</th>
              <th>Done by</th>
              <th>Do before</th>
              <th>Assigned to</th>
              <th>Created by</th>
              <th>Created on</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
            </tr>
          </thead>

          <transition-group
            name="staggered-fade"
            v-bind:css="false"
            v-on:before-enter="beforeEnter"
            v-on:enter="enter"
            leave-active-class="bounceOut"
            tag="tbody"
          >
            <ToDoItem
              v-for="(todo, index) in filteredToDos"
              :key="'todo_' + todo.Id"
              :data-index="index"
              :todo="todo"
              :viewType="viewType"
              :showLink="showLink"
              :showItemName="showItemName"
              @deleteToDo="setShowDeleteToDo"
              @editToDo="setShowEditToDo"
              @shareToDo="setShowShareToDo"
              @todoUpdated="todoUpdated"
            />
          </transition-group>
        </table>
      </transition-group>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import staggeredAnimation from '@/mixins/staggeredAnimation'
import todoProvider from '@/providers/todo'

const CreateToDo = () => import('@/components/ToDos/ModalCreateToDo')
const DeleteToDo = () => import('@/components/ToDos/ModalDeleteToDo')
const EditToDo = () => import('@/components/ToDos/ModalEditToDo')
const ShareToDo = () => import('@/components/ToDos/ModalShareToDo')
const ToDoItem = () => import('@/components/ToDos/ToDoItem')
const Message = () => import('@/components/UI/Message')

export default {
  components: {
    CreateToDo,
    DeleteToDo,
    EditToDo,
    ShareToDo,
    ToDoItem,
    Message,
  },

  props: {
    getFresh: {
      default: true,
      type: Boolean,
    },

    header: {
      default: '',
      type: String,
    },

    itemId: {
      default: 0,
      type: Number,
    },

    locationId: {
      default: 0,
      type: Number,
    },

    todos: {
      default: function () {
        return []
      },
      type: Array,
    },

    typeId: {
      default: 'None',
      type: String,
    },

    viewType: {
      default: 'normal',
      type: String,
    },

    showStatusMenu: {
      type: Boolean,
      default: true,
    },

    showItemName: {
      type: Boolean,
      default: false,
    },

    showLink: {
      type: Boolean,
      default: false,
    },

    workEnvironment: {
      type: String,
      default: 'Channel',
    },

    environmentId: {
      type: Number,
      default: 0,
    },
  },

  mixins: [staggeredAnimation],

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      isLoading: false,
      isLoadingLeft: false,
      isLoadingRight: false,
      mToDos: this.todos,
      startDate: new Date(),
      endDate: new Date(),
      selectedToDo: null,
      showCreateToDo: false,
      showDeleteToDo: false,
      showEditToDo: false,
      showShareToDo: false,
      toDoView: 'allToDos',
    }
  },

  computed: {
    ...mapGetters({
      profile: 'profileStore/getProfile',
    }),

    filteredToDos() {
      if (this.toDoView === 'allToDos') {
        return this.mToDos
      }
      if (this.toDoView === 'openToDos') {
        let d = this.startDate
        d = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0)
        return this.mToDos.filter((t) => t.DoBefore >= d.getTime() && !t.IsDone)

        // return this.mToDos.filter((t) => !t.IsDone)
      }
      if (this.toDoView === 'expiredToDos') {
        let d = this.startDate
        d = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0)
        return this.mToDos.filter((t) => t.DoBefore <= d.getTime())
      }
      if (this.toDoView === 'completedToDos') {
        return this.mToDos.filter((t) => t.IsDone)
      }
      return this.mToDos
    },
  },

  created() {},

  mounted() {
    let self = this

    if (self.getFresh) {
      self.getToDos()
    }
  },

  beforeDestroy() {},

  methods: {
    getToDos() {
      this.isLoading = true

      switch (this.typeId) {
        case 'Location':
          this.getChannelLocationToDos()
          break
        case 'Company':
          this.getCompanyToDos()
          break
        case 'Invoice':
          this.getInvoiceToDos()
          break
        case 'Customer':
          this.getProfileToDos()
          break
        case 'Quote':
          this.getQuoteToDos()
          break
        case 'Reservation':
          this.getReservationToDos()
          break
        case 'Voucher':
          this.getVoucherToDos()
          break
        case 'ReservationOption':
          this.getReservationOptionToDos()
          break
        case 'Review':
          this.getReviewToDos()
          break
        case 'ProposalRequest':
          this.getProposalRequestToDos()
          break
        default:
          this.getAssignedToDos()
          break
      }
    },

    updateDates(data) {
      this.startDate = data.date
      this.endDate = data.date
    },

    getChannelLocationToDos() {
      let self = this
      todoProvider.methods
        .getChannelLocationToDos(self.channelId, self.itemId)
        .then((response) => {
          if (response.status === 200) {
            self.mToDos = response.data
          }
        })
        .finally(() => {
          self.isLoading = false
        })
    },

    getCompanyToDos() {
      let self = this
      todoProvider.methods
        .getCompanyToDos(self.itemId, self.locationId)
        .then((response) => {
          if (response.status === 200) {
            self.mToDos = response.data
          }
        })
        .finally(() => {
          self.isLoading = false
        })
    },

    getInvoiceToDos() {
      let self = this
      todoProvider.methods
        .getInvoiceToDos(self.itemId)
        .then((response) => {
          if (response.status === 200) {
            self.mToDos = response.data
          }
        })
        .finally(() => {
          self.isLoading = false
        })
    },

    getProfileToDos() {
      let self = this
      todoProvider.methods
        .getProfileToDos(self.itemId, self.locationId)
        .then((response) => {
          if (response.status === 200) {
            self.mToDos = response.data
          }
        })
        .finally(() => {
          self.isLoading = false
        })
    },

    getReservationToDos() {
      let self = this
      todoProvider.methods
        .getReservationToDos(self.itemId)
        .then((response) => {
          if (response.status === 200) {
            self.mToDos = response.data
          }
        })
        .finally(() => {
          self.isLoading = false
        })
    },

    getReviewToDos() {
      let self = this
      todoProvider.methods
        .getReviewToDos(self.itemId)
        .then((response) => {
          if (response.status === 200) {
            self.mToDos = response.data
          }
        })
        .finally(() => {
          self.isLoading = false
        })
    },

    getProposalRequestToDos() {
      let self = this
      todoProvider.methods
        .getProposalRequestToDos(self.itemId)
        .then((response) => {
          if (response.status === 200) {
            self.mToDos = response.data
          }
        })
        .finally(() => {
          self.isLoading = false
        })
    },

    getVoucherToDos() {
      let self = this
      todoProvider.methods
        .getVoucherToDos(self.itemId)
        .then((response) => {
          if (response.status === 200) {
            self.mToDos = response.data
          }
        })
        .finally(() => {
          self.isLoading = false
        })
    },

    getReservationOptionToDos() {
      let self = this
      todoProvider.methods
        .getReservationOptionToDos(self.itemId)
        .then((response) => {
          if (response.status === 200) {
            self.mToDos = response.data
          }
        })
        .finally(() => {
          self.isLoading = false
        })
    },

    getAssignedToDos() {
      let self = this

      todoProvider.methods
        .getToDos(self.locationId, true)
        .then((response) => {
          if (response.status === 200) {
            self.mToDos = response.data
          }
        })
        .finally(() => {
          self.isLoading = false
        })
    },

    todoDeleted(todoToDelete) {
      let self = this
      let todoIndex = self.mToDos.findIndex((t) => t.Id === todoToDelete.Id)
      if (todoIndex > -1) {
        self.mToDos.splice(todoIndex, 1)
      }
    },

    todoCreated(newToDo) {
      let self = this
      self.mToDos.unshift(newToDo)

      self.getToDos()
    },

    todoUpdated(todo) {
      let todoIndex = this.mToDos.findIndex((t) => t.Id === todo.Id)
      if (todoIndex > -1) {
        Vue.set(this.mToDos, todoIndex, todo)
      }

      this.getToDos()
    },

    setShowCreateToDo() {
      this.showCreateToDo = true
    },

    setShowDeleteToDo(todo) {
      this.selectedToDo = todo
      this.showDeleteToDo = true
    },

    setShowEditToDo(todo) {
      this.selectedToDo = todo
      this.showEditToDo = true
    },

    setShowShareToDo(todo) {
      this.selectedToDo = todo
      this.showShareToDo = true
    },

    hideModal() {
      this.selectedToDo = null
      this.showCreateToDo = false
      this.showDeleteToDo = false
      this.showEditToDo = false
      this.showShareToDo = false
    },
  },
}
</script>

import axios from 'axios'
import store from '@/store'

export default {
  methods: {
    async getToDos(locationId, showAll) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/todo`,
        {
          params: {
            locationId: locationId,
            showAll: showAll
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getCompanyToDos(companyId, locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/todo/company/${companyId}/location/${locationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getChannelToDos(channelId, type, itemId, page, itemsPerPage) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/${channelId}/todos`,
        {
          params: {
            type: type,
            itemId: itemId,
            page: page,
            itemsPerPage: itemsPerPage
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getChannelLocationToDos(channelId, locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/todo/channel/${channelId}/location/${locationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getInvoiceToDos(invoiceId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/todo/invoice/${invoiceId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getProfileToDos(profileId, locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/todo/customer/${profileId}/location/${locationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getReservationToDos(reservationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/todo/reservation/${reservationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getVoucherToDos(voucherId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/todo/voucher/${voucherId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getReservationOptionToDos(reservationoptionId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/todo/reservationoption/${reservationoptionId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteToDo(todoId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/todo/${todoId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async createToDo(todo) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/todo`,
        JSON.stringify(todo),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async updateToDo(todo) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/todo/${todo.Id}`,
        JSON.stringify(todo),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async shareToDo(todoId, locationId, accountIds) {
      let criteria = {
        ToDoId: todoId,
        LocationId: locationId,
        AccountIds: accountIds
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/todo/${todoId}/share`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async setToDoStatus(todo) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/todo/${todo.Id}/done`,
        JSON.stringify(todo),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    }
  }
}
